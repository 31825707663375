import { FaCheckCircle, FaInfoCircle, FaExclamationCircle, FaTimes } from 'react-icons/fa';
import { Wrapper, CloseButton } from './Alert.styles';

const ICONS_RECORD = {
  info: <FaInfoCircle />,
  success: <FaCheckCircle />,
  error: <FaExclamationCircle />,
};

export const Alert = ({ message, options, style, close }) => {
  const icon = ICONS_RECORD[options.type];
  return (
    <Wrapper style={style} data-type={options.type}>
      {icon}&nbsp;
      {message}
      <CloseButton onClick={close}>
        <FaTimes />
      </CloseButton>
    </Wrapper>
  );
};
