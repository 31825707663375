import { useEffect, useState } from 'react';
import { SessionProvider } from 'next-auth/react';
import { Provider as AlertProvider } from 'react-alert';
import { GlobalStyle } from 'style/GlobalStyle';
import { ThemeAppProvider } from 'hooks/useTheme';
import { LoginPopupProvider } from 'hooks/useLoginPopup';
import { Alert, AlertQuery } from 'components/Alert';
import { LoginActions } from 'components/LoginActions';
import { MenuProvider } from 'hooks/useMenu';
import { readNotification } from 'apis/others';
import NextNProgress from 'nextjs-progressbar';
import { useRouter } from 'next/router';
import 'style/fonts.css';
import { QueryClient, QueryClientProvider, Hydrate } from 'react-query';

const App = ({ Component, pageProps }) => {
  const [queryClient] = useState(() => new QueryClient());
  const router = useRouter();

  useEffect(() => {
    const { n_id } = router.query;
    if (n_id && !Number.isNaN(+n_id)) {
      readNotification({ id: n_id }).then().catch();
    }
  }, [router.query]);

  return (
    <>
      <SessionProvider session={pageProps.session}>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <MenuProvider>
              <ThemeAppProvider>
                <AlertProvider
                  timeout={5000}
                  template={Alert}
                  containerStyle={{
                    zIndex: 1500,
                  }}
                >
                  <LoginPopupProvider>
                    <GlobalStyle />
                    <AlertQuery />
                    <NextNProgress />
                    <LoginActions />
                    <Component {...pageProps} />
                  </LoginPopupProvider>
                </AlertProvider>
              </ThemeAppProvider>
            </MenuProvider>
          </Hydrate>
        </QueryClientProvider>
      </SessionProvider>
    </>
  );
};

export default App;
