import { useQuery } from 'react-query';
import { send } from './_';

export const getAdSlots = () => send(`/api/ad_slots`);

export const sendVoteInThePoll = (options) => send(`/api/polls/vote`, 'post', options);

export const sendContactMessage = (data) => send(`/api/contact`, 'post', data);

export const useGetNotifications = () =>
  useQuery(['notifications'], () => send(`/api/notifications`), {
    refreshInterval: 20_000,
    staleTime: 10_000,
  });

export const deleteAllNotification = () => send(`/api/notifications`, 'delete');

export const readNotification = ({ id }) => send(`/api/notifications/${id}`, 'post');

export const deleteNotification = ({ id }) => send(`/api/notifications/${id}`, 'delete');

export const updateVote = (options) => send(`/api/challanges`, 'post', options);

export const useGetChallange = ({ id = '', initialData }) =>
  useQuery(['challanges', id], () => send(`/api/challanges${id ? `/${id}` : ''}`), {
    refreshInterval: 30_000,
    staleTime: 30_000,
    initialData,
  });
