import { createContext, useContext, useState } from 'react';
// import { LoginPopup } from 'components/LoginPopup';
import dynamic from 'next/dynamic';

const LoginPopup = dynamic(() => import('components/LoginPopup').then((v) => v.LoginPopup), { ssr: false });

export const LoginPopupContext = createContext();

export const LoginPopupProvider = ({ children }) => {
  const [isLoginPopupVisible, setLoginPopup] = useState(false);

  return (
    <LoginPopupContext.Provider value={{ isLoginPopupVisible, setLoginPopup }}>
      {children}
      {isLoginPopupVisible && <LoginPopup />}
    </LoginPopupContext.Provider>
  );
};

export const useLoginPopup = () => useContext(LoginPopupContext);
