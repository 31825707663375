import axios from 'axios';

export const send = async (url, method = 'get', options = {}, headers = {}) => {
  if (typeof window !== 'undefined' && !window.navigator.onLine) {
    throw new Error({ error: 'no internet' });
  }
  const { data } = await axios({ url, method, data: options, headers });
  return data;
};

export const createSend =
  (urlPrefix) =>
  (url, ...restArgs) =>
    send(`${urlPrefix}${url}`, ...restArgs);
