import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 20px;
  min-height: 40px;
  padding: 0 25px 0 12px;
  color: ${({ type }) => (type === 'info' ? 'black' : 'white')};
  box-shadow: 1px 1px 10px 3px rgba(0, 0, 0, 0.3);
  background-color: var(--primary);
  &[data-type='error'] {
    background-color: var(--danger);
  }
  &[data-type='success'] {
    background-color: var(--success);
  }
  border-radius: 4px;
  position: relative;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0;
  color: black;
  font-size: 16px;
`;
