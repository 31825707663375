import { createSend } from './_';

const userSend = createSend('/api/users');

export const register = (options) => userSend('/register', 'post', options);

export const updateProfile = (options) =>
  userSend(`/profile`, 'put', options, { 'Content-Type': 'multipart/form-data' });

export const findPublicProfile = (options) => userSend('/check', 'post', options);

export const sendRecoverPassword = (options) => userSend('/password', 'post', options);

export const createNewPassword = (options) => userSend('/password', 'put', options);

export const getUserPublicData = (id) => userSend(`/profile/${id}`);

export const userDeleteOwnAccount = () => userSend('/deleteAccount', 'delete');

export const getUsers = (login) => userSend(`/?login=${login}`);
