import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
   :root {
      --header-height: clamp(55px, 10vw, 80px);
      --app-width: min(1200px, 100%);
      --primary: #FFB718;
      --secondary: #189bff;
      --danger: #C25152;
      --success: #51c264;
      --br: 4px;
      --display-ads: "flex";

      --bg-light: #212838;
      --bg-medium: #191E2C;
      --bg-strong: #121725;
      --font: #fff;
      --font-lighter: rgb(168, 182, 192);
      --opacity-v-light: rgba(255, 255, 255, 0.05);
      --opacity-light: rgba(255, 255, 255, 0.1);
      --opacity-medium: rgba(255, 255, 255, 0.3);
      --opacity-strong: rgba(255, 255, 255, 0.5);
      --navbar-boxshadow: 0;
      --navbar-item-hover: rgba(255, 255, 255, 0.2);
      --article-metadata-color: white;
   }

   [data-theme="light"] {
      --bg-light: rgb(250, 250, 255);
      --bg-medium: rgb(251 251 251 / 78%);
      --bg-strong: rgb(251, 251, 251);
      //--opacity-v-light: rgb(244, 241, 234);
      --opacity-v-light: rgb(245, 243, 236);
      --font: #000;
      --font-lighter: rgb(107, 107, 107);
      --opacity-light: rgb(200 200 200 / 10%);
      --opacity-medium: rgba(0, 0, 0, 0.3);
      --opacity-strong: rgba(0, 0, 0, 0.5);
      --navbar-boxshadow: 0 0 15px -10px rgb(0 0 0 / 35%);
      --navbar-item-hover: rgb(228, 228, 228);
      --article-metadata-color: black;
   }

   * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
   }

   ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
   }

   ::-webkit-scrollbar-track {
      background: transparent;
   }

   ::-webkit-scrollbar-thumb {
      background-color: var(--primary);
      border-radius: 6px;
      border: 2px solid transparent;
      border-left: 0;
      border-left: none;
   }

   html {
      scroll-behavior: smooth;
      -webkit-font-smoothing: antialiased;

   }

   body, #__next {
      width: 100%;
      min-height: 100%;
   }

   body {
      font-family: EuclidCircular, Arial;
      -webkit-font-smoothing: antialiased;
      background-color: var(--bg-medium);
      color: var(--font);

   }

   button, a, input, textarea {
      cursor: pointer;
      color: inherit;
      font-family: inherit;
      background-color: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
   }

   a {
      text-decoration: none;
   }

   h1 {
      font-size: 2em;
      display: block;
      line-height: 40px;
      margin: 10px 5px;
   }

   hr {
      width: 99%;
      margin: 5px auto;
      background-color: var(--opacity-strong);
      border: none;
      height: 1px;
   }

   #sg_bill_top,
   #sg_bill_bottom,
   #art_bill_top,
   #art_bill_bottom,
   #art_bill_srodek_3,
   #sekcja_bill_top,
   #sekcja_bill_srodek_1,
   #sekcja_bill_srodek_2,
   #sg_bill_srodek_3,
   #sekcja_bill_bottom {
      max-width: 970px;
      width: 100%;
      min-height: 265px;
   }

   #sg_bill_srodek_1,
   #sg_bill_srodek_2,
   #art_bill_srodek_1,
   #art_bill_srodek_2 {
      max-width: 750px;
      width: 100%;
      min-height: 295px;
      @media (max-width: 480px) {
         min-height: 215px;
      }
   }

   #sg_half_1, #sg_half_2,
   #art_half_1, #art_half_2 {
      width: 100%;
      min-height: 615px;
      margin: 5px auto;
   }

   [id$=_top] {
      min-height: 295px;
   }

   input, textarea {
      cursor: auto;
      padding: 10px 13px;
      background-color: var(--bg-strong);
      color: var(--font);
      font-size: 16px;
      line-height: 20px;
      border: 1px solid var(--opacity-medium);
      border-radius: 4px;
      transition: border-color 0.3s;
      outline: none;

      &:focus, &:hover {
         border-color: var(--primary);
      }

      &::placeholder {
         color: #aaa;
      }

      &:disabled {
         border-color: var(--opacity-light);
         color: var(--opacity-medium);
         cursor: auto;
      }
   }

   input[type="checkbox"] {
      accent-color: var(--primary);
      margin: 3px;
   }

   #nprogress > .spinner {
      display: none
   }
`;
