import { useQuery } from 'react-query';
import { useAlert } from 'react-alert';
import * as user from './user';
import * as game from './game';
import * as payments from './payments';
import { send } from './_';

export const apis = {
  user,
  game,
  payments,
};

export const useGamesByTitle = ({ title = '' }) => {
  const alert = useAlert();
  return useQuery(['gamesByTitle', title], () => send(`/api/games/title/${title}`), {
    enabled: !!title,
    staleTime: 5_000,
    onError: () => {
      alert('Nie udało się pobrać gier');
    },
    select: (data) => data.map(({ id, title: label }) => ({ value: id, label })),
  });
};

export const useUsersByLogin = ({ login = '' }) => {
  const alert = useAlert();
  return useQuery(['usersByLogin', login], () => send(`/api/users/search/${login}`), {
    enabled: !!login,
    staleTime: 5_000,
    onError: () => {
      alert('Nie udało się pobrać użytkowników');
    },
    select: (data) => data.map(({ id, login: label }) => ({ value: id, label })),
  });
};

export const usePollsByTitle = ({ title = '' }) => {
  const alert = useAlert();
  return useQuery(['pollsByTitle', title], () => send(`/api/polls?title=${title}`), {
    enabled: !!title,
    staleTime: 5_000,
    onError: () => {
      alert('Nie udało się pobrać ankiet');
    },
    select: (data) => data.map(({ id, title: label }) => ({ value: id, label })),
  });
};

export const useTagsByTag = ({ tag = '' }) => {
  const alert = useAlert();
  return useQuery(['tagsByTag', tag], () => send(`/api/tags/${tag}`), {
    enabled: !!tag,
    staleTime: 5_000,
    onError: () => {
      alert('Nie udało się pobrać tagów');
    },
  });
};

export const useCachedData = ({ type }, options) =>
  useQuery(['cached', type], () => send(`/api/cached/${type}`), { staleTime: 60_000, ...options });

export const usePosts = ({ page = '1', q = '', tag = '' }) =>
  useQuery(['posts', page, q, tag], () => send(`/api/posts?page=${page}&q=${q}&tag=${tag}`), {
    staleTime: 30_000,
    refetchInterval: 30_000,
  });

export const useRecentMessagesList = ({ q = '' }) =>
  useQuery(['recentMessages', q], () => send(`/api/messages/recent?q=${q}`), {
    refreshInterval: 10_000,
    staleTime: 10_000,
  });

export const useGetDirectMessages = ({ userId }) =>
  useQuery(['directMessages', userId], () => send(`/api/messages/direct/${userId}`), {
    refreshInterval: 10_000,
    staleTime: 10_000,
    enabled: userId !== undefined,
  });

export const sendMessage = ({ userId, options }) => send(`/api/messages/direct/${userId}`, 'post', options);

export const sendReadedMessages = (id) => send(`/api/messages/readed/${id}`, 'post');
export const sendReadedMessagesAll = () => send(`/api/messages/readedAll`, 'post');

export const useGames = (query) =>
  useQuery(['games', query], () => send(`/api/games?${new URLSearchParams(query).toString()}`), {
    staleTime: 20_000,
  });

export const useComments = ({ contentType, contentId }, options) =>
  useQuery(['comments', contentType, contentId], () => send(`/api/comments/${contentType}/${contentId}`), {
    staleTime: 20_000,
    refreshInterval: 30_000,
    ...options,
  });

export const addComment = (options) => send('/api/comment', 'post', options);

export const editComment = ({ commentId, value }) => send(`/api/comment/${commentId}`, 'put', { value });

export const deleteComment = ({ commentId }) => send(`/api/comment/${commentId}`, 'delete');

export const addCommentPoint = ({ commentId, point }) => send(`/api/comment/${commentId}/likes`, 'post', { point });

export const reportComment = ({ commentId, isReported }) =>
  send(`/api/comment/${commentId}/report`, 'post', { isReported });

export const hideComment = ({ commentId, isHidden }) => send(`/api/comment/${commentId}/hide`, 'post', { isHidden });

export const useSession = () =>
  useQuery(['session'], () => send(`/api/auth/session`), {
    staleTime: 20_000,
    refreshInterval: 30_000,
    select: (data) => (data && Object.entries(data).length ? data : null),
  });

export const useRefreshActive = () => {
  const { data } = useSession();
  return useQuery(['activePing'], () => send('/api/users/active'), {
    enabled: !!data,
    refreshInterval: 30_000,
  });
};

export const useActivePoll = ({ pollId = '' }) =>
  useQuery(['activePoll'], () => send(`/api/polls/active${pollId ? `?id=${pollId}` : ''}`), {
    refreshInterval: 30_000,
    staleTime: 30_000,
  });

export const useCalendar = ({ date }) =>
  useQuery(['calendar', date.slice(0, 7)], () => send(`/api/events?date=${date}`), {
    refreshInterval: 60_000,
    staleTime: 60_000,
  });
