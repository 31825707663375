const mediaQuery = {
  tablet: `min-width: 768px`,
  desktop: `min-width: 1024px`,
  maxContent: 'min-width: 1170px',
  large: `min-width: 1440px`,
};

export const themes = {
  mediaQuery,
};
