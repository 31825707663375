import { createContext, useContext, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { themes } from 'style/theme';

export const ThemeAppContext = createContext();

export const ThemeAppProvider = ({ children }) => {
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    const item = localStorage.getItem('theme');
    const newTheme = item === 'light' ? 'light' : 'dark';
    setTheme(newTheme);
  }, []);

  useEffect(() => {
    document.body.dataset.theme = theme;
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevState) => {
      const state = prevState === 'light' ? 'dark' : 'light';
      localStorage.setItem('theme', state);
      return state;
    });
  };

  return (
    <ThemeAppContext.Provider value={{ theme, toggleTheme }}>
      <ThemeProvider theme={themes}>{children}</ThemeProvider>
    </ThemeAppContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeAppContext);
