import { useEffect } from 'react';
import { useRefreshActive, useSession } from 'apis';

export const LoginActions = () => {
  const { data: session } = useSession();

  useRefreshActive();

  useEffect(() => {
    const userExit = () => {
      if (session) {
        navigator.sendBeacon('/api/users/active');
      }
    };
    window.addEventListener('beforeunload', userExit);
    return () => {
      window.removeEventListener('beforeunload', userExit);
    };
  }, [session]);

  return null;
};
