import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useAlert } from 'react-alert';

const alertTypes = {
  invalidToken: { text: 'Błędny token', type: 'error' },
  registerSuccessed: { text: 'Możesz się już zalogować', type: 'success' },
  unexprectedError: { text: 'Wystąpił nieoczekiwany błąd', type: 'error' },
};

export const AlertQuery = () => {
  const router = useRouter();
  const reactAlert = useAlert();

  useEffect(() => {
    const { alert } = router.query;
    const { text, type = 'info' } = alertTypes[alert] || {};

    if (text) {
      reactAlert[type](text);
    }
  }, [router.query, reactAlert]);

  return null;
};
